import * as React from 'react';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  NotFound,
  InternalServerError,
  SignIn,
  ResetPassword,
  UpdatePassword,
  AzureAd
} from '@pages';
import { Layout } from './layouts/Layout';

export const App: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<SignIn />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/azure" element={<AzureAd />} />
      </Route>

      <Route path="*" element={<NotFound />} />
      <Route path="internal-error" element={<InternalServerError />} />
    </Routes>
  );
};
