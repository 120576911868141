import * as React from 'react';
import {
  LogoExquanceIcon,
  Dropdown,
  offsets,
  NotificationContainer,
  Image
} from '@xq/ui-kit';
import styles from './Layout.module.scss';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { config } from '@config';
import { currentLang, languages } from '@constants';

export const Layout: FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    try {
      window.localStorage.setItem(config.localStorageLang, value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className={styles.layout}>
        <Image className={styles.image} />
        <div className={styles.main}>
          <LogoExquanceIcon className={offsets['mb-60']} />
          <div className={styles.block}>
            <Outlet />
          </div>
        </div>
      </div>
      <Dropdown
        selectedItemValue={currentLang()}
        className={styles.dropdown}
        items={languages}
        onValueSelected={changeLanguage}
        position={'top'}
        noTextTransform={true}
      />
      <NotificationContainer />
    </div>
  );
};

Layout.displayName = 'Layout';
