import { config } from '@config';
import { SelectItem } from '@xq/ui-kit';

export const languages: SelectItem[] = [
  {
    label: 'en',
    value: 'EN'
  },
  {
    label: 'fi',
    value: 'FI'
  }
  /* Hide ru lang */
  // {
  //   label: 'ru',
  //   value: 'RU'
  // }
];

export const currentLang = (): string => {
  let lang = config.defaultLang;
  try {
    const savedLang = window.localStorage.getItem(config.localStorageLang);
    if (
      savedLang &&
      languages.find((el) => el.value.toLowerCase() === savedLang.toLowerCase())
    ) {
      lang = savedLang;
    }
  } catch (error) {
    console.error('Local storage error:', error);
  }
  return lang;
};
