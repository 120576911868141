import { ResponseError } from '@xq/iam-frontend-client';
import {
  BackendErrorResponse,
  DEFAULT_NOTIFICATION_DELAY,
  Logger,
  LogLevel,
  openNotification,
  openStatusNotification
} from '@xq/ui-kit';
import { config } from '@config';
import { getStatusNotificationTranslations } from '@services/notifications';

export const logger = new Logger({ level: config.logLevel as LogLevel });
logger.hideConsoleLogs();

export async function handleBackendError(
  error: ResponseError,
  className: string,
  functionName: string
) {
  const description = `function ${functionName} in class ${className}`;

  const errorBody: BackendErrorResponse = await error?.response?.json();
  if (errorBody) {
    backendErrorLog(errorBody, description);
  } else {
    errorLog(error, description);
  }
  throw errorBody || error;
}

export const backendErrorLog = (
  error: BackendErrorResponse,
  description?: string
) => {
  logger.error(
    `Error ${error.error}, message: ${error.message}, status: ${error.status}${
      description ? ', description: ' + description : ''
    }, service: sso`
  );
};

export const errorLog = (error: Error, description?: string) => {
  logger.error(
    `Error name: ${error.name}, message: ${error.message}${
      description ? ', description: ' + description : ''
    }, service: sso`
  );
};
