import { HeaderAppItem, HeaderAppNames } from '@components/Header/dataTypes';

export const MODEL_TREE_DESKTOP_UUID = 'afd851cb-bb8e-4b16-9f4f-597445b0177f';
export const INSIGHTS_UUID = 'b4749d52-34ec-4905-8c0b-ca56fc9c0dca';

export interface FrontendAppUrls {
  modelTreeFrontendUrl: string;
  insightsFrontendUrl: string;
}

export const getLicenseProductByUUID = (
  uuid: string,
  urls: FrontendAppUrls
): HeaderAppItem => {
  switch (uuid) {
    case MODEL_TREE_DESKTOP_UUID: {
      return {
        name: HeaderAppNames.ModelTree,
        url: urls.modelTreeFrontendUrl
      };
    }
    case INSIGHTS_UUID: {
      return {
        name: HeaderAppNames.Insights,
        url: urls.insightsFrontendUrl
      };
    }
    default: {
      return;
    }
  }
};
