import * as React from 'react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageError } from '@xq/ui-kit';

export const NotFound: FC = () => {
  const navigate = useNavigate();

  return (
    <PageError code={404} title={'Ooops! Page not found'} navigate={navigate} />
  );
};

NotFound.displayName = 'NotFound';
