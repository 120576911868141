import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { config } from '@config';
import { currentLang } from '@constants';

const backEnds = [HttpBackend];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    defaultNS: 'translation',
    fallbackLng: [currentLang(), config.defaultLang],
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      backends: backEnds
    }
  });

export default i18n;
