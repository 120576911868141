import {
  wait,
  iamGateway,
  handleBackendError,
  iamApiConfiguration
} from '@services';
import { TwoFactorAuth } from '@pages/SignIn/dataTypes';
import { appVersion } from '../../constants';
import { AuthAzureADWebApi } from '@xq/iam-frontend-client';

export interface AuthForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface SignInService {
  authCheck(): Promise<void>;
  signIn(model: AuthForm): Promise<TwoFactorAuth>;
  signInTwoFactor(twoFactorId: string, code: string): Promise<void>;
  resendTwoFactorCode(twoFactorId: string): Promise<number>;
  getLogInAzureAdLink(): Promise<string>;
}

const iamAuthAzureADGateway = new AuthAzureADWebApi(iamApiConfiguration);

export class SignInServiceApi implements SignInService {
  async authCheck(): Promise<void> {
    try {
      await iamGateway.authControllerCheck();
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async signIn(model: AuthForm): Promise<TwoFactorAuth> {
    try {
      const response = await iamGateway.authControllerLogin({
        authDTORequestLogin: {
          email: model.email,
          password: model.password,
          rememberMe: model.rememberMe
        }
      });
      return response?.twoFactor;
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.signIn.name);
    }
  }

  async signInTwoFactor(twoFactorId: string, code: string): Promise<void> {
    try {
      await iamGateway.authControllerLoginTwoFactor({
        authDTORequestLoginTwoFactor: {
          twoFactorId,
          code
        },
        xqAppVersion: appVersion?.version,
        xqAppName: appVersion?.name
      });
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.signIn.name);
    }
  }

  async resendTwoFactorCode(twoFactorId: string): Promise<number> {
    try {
      const response = await iamGateway.authControllerResendTwoFactorCode({
        twoFactorId: twoFactorId,
        xqAppVersion: appVersion?.version,
        xqAppName: appVersion?.name
      });
      return response?.resendCodeDelayInSec;
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.signIn.name);
    }
  }

  async getLogInAzureAdLink(): Promise<string> {
    try {
      const response =
        await iamAuthAzureADGateway.authAzureADWebControllerGetLogInLink({
          xqAppVersion: appVersion?.version,
          xqAppName: appVersion?.name
        });
      return response;
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getLogInAzureAdLink.name
      );
    }
  }
}

export class AuthenticationServiceMock implements SignInService {
  async authCheck(): Promise<void> {
    try {
      await wait(100);
      // throw new Error('already logged in ')
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.authCheck.name
      );
    }
  }

  async signIn(model: AuthForm): Promise<TwoFactorAuth> {
    try {
      await wait(1000);
      return {
        twoFactorId: 'adf8cfed-9b94-4cf5-aef6-c8274d0aa3c3',
        method: 'email',
        resendCodeDelayInSec: 30
      };
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.signIn.name);
    }
  }

  async signInTwoFactor(twoFactorId: string, code: string): Promise<void> {
    try {
      await wait(1000);
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.signIn.name);
    }
  }

  async resendTwoFactorCode(twoFactorId: string): Promise<number> {
    try {
      await wait(1000);
      return 30;
    } catch (error) {
      await handleBackendError(error, this.constructor.name, this.signIn.name);
    }
  }

  async getLogInAzureAdLink(): Promise<string> {
    try {
      await wait(1000);
      return 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=41108fbf-90c1-4670-94cb-6bd8e400f853&scope=user.read%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fdev.api.exquance.com%2Fapi%2Fauth-azure-ad-web%2Fredirect&client-request-id=32ce6dad-f853-493f-a08f-55c122d4373c&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=2.6.3&x-client-OS=linux&x-client-CPU=x64&client_info=1';
    } catch (error) {
      await handleBackendError(
        error,
        this.constructor.name,
        this.getLogInAzureAdLink.name
      );
    }
  }
}
