import React, { useState, FC, useEffect, useMemo } from 'react';
import styles from './UpdatePassword.module.scss';
import {
  Typography,
  Input,
  Button,
  offsets,
  flex,
  openNotification,
  display,
  openStatusNotification,
  DEFAULT_NOTIFICATION_DELAY
} from '@xq/ui-kit';
import classNames from 'classnames';
import { UpdatePasswordService } from './update-password-service';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getStatusNotificationTranslations, submitForm } from '@services';
import { PasswordRestriction } from '@pages/UpdatePassword/dataTypes';

const defaultPasswordRestrictions: PasswordRestriction[] = [
  {
    title: 'updatePassword.charactersMinimumRestriction',
    regex: /^.{9,}$/,
    isValid: false
  },
  {
    title: 'updatePassword.charactersMaximumRestriction',
    regex: /^(.{0,60})$/,
    isValid: false
  },
  {
    title: 'updatePassword.lowercaseLetterRestriction',
    regex: /[a-z]/,
    isValid: false
  },
  {
    title: 'updatePassword.uppercaseLetterRestriction',
    regex: /[A-Z]/,
    isValid: false
  },
  {
    title: 'updatePassword.numberRestriction',
    regex: /\d/,
    isValid: false
  },
  {
    title: 'updatePassword.specialCharacterRestriction',
    regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
    isValid: false
  },
  {
    title: 'updatePassword.specialCharacterRestriction',
    regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
    isValid: false
  }
];

export const UpdatePassword: FC = () => {
  const service: UpdatePasswordService = new UpdatePasswordService();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const [passwordRestrictions, setPasswordRestrictions] = useState<
    PasswordRestriction[]
  >(defaultPasswordRestrictions);

  const isRestrictionsValid: boolean = useMemo(() => {
    return passwordRestrictions?.reduce((result, restriction) => {
      return result && restriction.isValid;
    }, true);
  }, [passwordRestrictions]);

  const testRegex = (regex: RegExp, value: string): boolean => {
    return value && regex.test(value);
  };

  const isFieldEmpty = (field: string): boolean => {
    return field.length === 0;
  };

  const isPasswordsMatch = useMemo(() => {
    return password && confirmPassword && password === confirmPassword;
  }, [password, confirmPassword]);

  useEffect(() => {
    const isNotValid: boolean =
      isFieldEmpty(password) ||
      isFieldEmpty(confirmPassword) ||
      password !== confirmPassword ||
      !isRestrictionsValid;
    setDisabled(isNotValid);
  }, [password, confirmPassword, isRestrictionsValid]);

  useEffect(() => {
    const updatedRestrictions: PasswordRestriction[] = passwordRestrictions.map(
      (restriction) => {
        const isValid = testRegex(restriction.regex, password);
        return { ...restriction, isValid };
      }
    );
    setPasswordRestrictions(updatedRestrictions);
  }, [password]);

  async function resetPassword() {
    if (disabled) {
      openNotification(
        t('updatePassword.passwordsDontMatch'),
        'error',
        DEFAULT_NOTIFICATION_DELAY
      );
      throw new Error('Validation error');
    }

    setIsLoading(true);

    try {
      const changePasswordId = searchParams.get('changePasswordId');
      if (!changePasswordId) {
        openNotification(
          t('updatePassword.passwordIdNotFound'),
          'error',
          DEFAULT_NOTIFICATION_DELAY
        );
        return;
      }
      await service.setPassword({
        password,
        changePasswordId
      });
      setPassword('');
      setConfirmPassword('');
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: 200,
        message: t('updatePassword.passwordResetSuccessfully')
      });
      navigate('/');
    } catch (error) {
      if (error?.status === 400) {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          message: t('updatePassword.passwordValidationMessage'),
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: error?.status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Typography className={styles.header} variant="h3">
        {t('updatePassword.setPassword')}
      </Typography>

      <form onSubmit={(e) => submitForm(e)}>
        <div className={offsets['my-20']}>
          <Input
            className={offsets['mb-20']}
            value={password}
            disabled={isLoading}
            onChange={(value) => setPassword(String(value))}
            label={t('updatePassword.newPassword')}
            type="password"
            withIcon
          />
          <Input
            value={confirmPassword}
            disabled={isLoading}
            onChange={(value) => setConfirmPassword(String(value))}
            label={t('common.confirm')}
            type="password"
            withIcon
          />
        </div>

        <ul className={styles['password-list']}>
          {passwordRestrictions.map((restriction, idx) => {
            return (
              <li
                key={idx}
                className={
                  restriction.isValid
                    ? styles['password-item--valid']
                    : styles['password-item']
                }
              >
                <Typography
                  variant={'body-4'}
                  element={'div'}
                  className={offsets['mb-10']}
                >
                  {t(restriction.title)}
                </Typography>
              </li>
            );
          })}
          <li
            className={
              isPasswordsMatch
                ? styles['password-item--valid']
                : styles['password-item']
            }
          >
            <Typography
              variant={'body-4'}
              element={'div'}
              className={offsets['mb-10']}
            >
              {t('updatePassword.passwordsMatch')}
            </Typography>
          </li>
        </ul>

        <div
          className={classNames(
            display['d-flex'],
            flex['justify-content-center'],
            offsets['mt-40']
          )}
        >
          <Button
            buttonType={'submit'}
            onClick={resetPassword}
            isLoading={isLoading}
            disabled={disabled}
          >
            {t('common.send')}
          </Button>
        </div>
      </form>
    </div>
  );
};

UpdatePassword.displayName = 'UpdatePassword';
