import { TFunction } from 'i18next';

export const handleTranslationErrorFromStatus = (
  t: TFunction,
  error: string
): string => {
  switch (error) {
    case 'IAM_USER_NOT_FOUND': {
      return t('signIn.incorrectEmailOrPassword');
    }
    case 'IAM_USER_IS_BLOCKED': {
      return t('signIn.userIsBlocked');
    }
    case 'IAM_SET_PASSWORD_TOKEN_NOT_FOUND': {
      return t('signIn.passwordTokenNotFound');
    }
    default: {
      break;
    }
  }
};
