import { FormEvent } from 'react';
import { config } from '@config';

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const submitForm = (e: FormEvent) => {
  e.preventDefault();
};

export const redirectToProfile = (redirectUrl?: string) => {
  if (redirectUrl) {
    redirectToURL(redirectUrl);
    return;
  }
  redirectToURL(config.accountFrontendUrl);
};

export const redirectToURL = (url: string, isNewTab?: true) => {
  if (isNewTab) {
    window.open(url, '_blank');
  } else {
    window.location.replace(url);
  }
};
