import * as React from 'react';
import { FC, useEffect } from 'react';
import {
  getStatusNotificationTranslations,
  redirectToProfile
} from '@services';
import { openStatusNotification, Typography } from '@xq/ui-kit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AzureAdService, AzureAdServiceApi } from './azure-ad-service';
import { useTranslation } from 'react-i18next';
import styles from './AzureAd.module.scss';

export const AzureAd: FC = () => {
  const service: AzureAdService = new AzureAdServiceApi();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  async function finishAzureADLogIn() {
    try {
      await service.finishAzureADLogIn(code);
      redirectToProfile();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
      navigate('/');
    }
  }

  useEffect(() => {
    finishAzureADLogIn();
  }, []);

  return (
    <div className={styles.title}>
      <Typography variant={'h1'}> AzureAd</Typography>
    </div>
  );
};

AzureAd.displayName = 'AzureAd';
