import { useCallback, useState } from 'react';

import { useEffect } from 'react';

const interval =
  (delay = 0) =>
  (callback: any) =>
    useEffect(() => {
      const id = setInterval(callback, delay);

      return () => clearInterval(id);
    }, [callback]);

const useSecondsInterval = interval(1000);

export const useTimer = ({
  initialSeconds = 0,
  initiallyRunning = false
} = {}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [running, setRunning] = useState(initiallyRunning);

  const tick = useCallback(() => {
    if (seconds === 1) {
      setRunning(false);
    }
    return running ? setSeconds((seconds) => seconds - 1) : undefined;
  }, [running, seconds]);

  const startTimer = (seconds: number) => {
    resetTimer();
    setRunning(true);
    setSeconds(seconds);
  };
  const pauseTimer = () => setRunning(false);
  const resetTimer = () => setSeconds(0);
  const stopTimer = () => {
    pauseTimer();
    resetTimer();
  };

  useSecondsInterval(tick);

  return { pauseTimer, resetTimer, running, seconds, startTimer, stopTimer };
};
